/* eslint-disable no-useless-escape */
import type { Platforms } from '~/types/mediaLinks'

const socialLinkRegex = {
  spotify:
    /^(https?:\/\/)?open.spotify.com\/(?:intl-[a-zA-Z]{2}\/)?(?<entityType>artist|playlist|show|user|track|album)\/(?<spotifyID>[a-zA-Z0-9\-\_\.]+)(([?&][a-zA-Z]+(=[a-zA-Z0-9-_]*)?)*)/,

  spotifyShort: /^(https?:\/\/)?spotify.link\/([a-zA-Z0-9]*)$/,
  facebook: /^(https?:\/\/)?((www)?\.)?facebook\.com\/([a-zA-Z0-9\.-])*/,
  deezer:
    /^(https?:\/\/)?((www)?\.)?deezer\.com(\/[a-zA-Z]*)?\/(artist|show|profile|playlist|album)*\/[0-9]*$/,
  bandcamp:
    /^(https?:\/\/)?(([a-zA-Z0-9\.-])*)?bandcamp\.com(\/([a-zA-Z0-9\_\-]*\/?))?$/,
  youtube:
    /^(https?:\/\/)?((www)?\.)?youtube\.com\/(channel\/|user\/|watch\?v=.*$|c\/|)([^\/\?\n]*)/,
  soundcloud: /^(https?:\/\/)?(www\.)?(on\.)?soundcloud\.com\/[\w\-]+(\?.*)?/,
  instagram:
    /^(https?:\/\/)?((www)?\.)?instagram\.com\/[a-zA-Z0-9\.\_]*\/?(\?.*)?$/,
  twitter: /^(https?:\/\/)?((www)?\.)?(twitter|x).com\/([a-zA-Z0-9\.-])+(\?([a-zA-Z0-9\.-=])*)?/,
  tiktok: /^(https?:\/\/)?((www)?\.)?tiktok\.com\/@[a-zA-Z0-9\.\_]+\/?(\?.*)?$/,
  dropbox:
    /^(https?:\/\/)?((www)?\.)?dropbox\.com\/([a-zA-Z0-9\.-_])*\/([a-zA-Z0-9\.-_])*\/([a-zA-Z0-9%\.-_])*/,
  googledrive:
    /^(https?:\/\/)?((www)?\.)?drive\.google\.com\/([a-zA-Z0-9\.-])*/,
  disco: /^(https?:\/\/)?s\.disco\.ac\/[a-z]*\/?$/,
} as Record<
  Platforms | 'googledrive' | 'dropbox' | 'disco' | 'spotifyShort',
  RegExp
>

export const getSpotifyRegex = (
  entityType:
    | 'album'
    | 'artist'
    | 'artist|track|album'
    | 'curator'
    | 'playlist'
    | 'playlist|user'
    | 'show'
    | 'spotify'
    | 'spotifyShort'
    | 'track'
    | 'track|album'
    | 'user',
) => {
  switch (entityType) {
    case 'artist':
    case 'artist|track|album':
      return /^(https?:\/\/)?open.spotify.com\/(?:intl-[a-zA-Z]{2}\/)?(?<entityType>artist|track|album)\/(?<spotifyID>[a-zA-Z0-9\-\_\.]+)(([?&][a-zA-Z]+(=[a-zA-Z0-9-_]*)?)*)/g
    case 'curator':
      return /^(https?:\/\/)?open.spotify.com\/(?:intl-[a-zA-Z]{2}\/)?(?<entityType>user)\/(?<spotifyID>[a-zA-Z0-9\-\_\.]+)(([?&][a-zA-Z]+(=[a-zA-Z0-9-_]*)?)*)/g
    case 'playlist|user':
      return /^(https?:\/\/)?open.spotify.com\/(?:intl-[a-zA-Z]{2}\/)?(?<entityType>playlist|user)\/(?<spotifyID>[a-zA-Z0-9\-\_\.]+)(([?&][a-zA-Z]+(=[a-zA-Z0-9-_]*)?)*)/g
    case 'track':
      return /^(https?:\/\/)?open.spotify.com\/(?:intl-[a-zA-Z]{2}\/)?(?<entityType>track)\/(?<spotifyID>[a-zA-Z0-9\-\_\.]+)(([?&][a-zA-Z]+(=[a-zA-Z0-9-_]*)?)*)/g
    case 'album':
      return /^(https?:\/\/)?open.spotify.com\/(?:intl-[a-zA-Z]{2}\/)?(?<entityType>album)\/(?<spotifyID>[a-zA-Z0-9\-\_\.]+)(([?&][a-zA-Z]+(=[a-zA-Z0-9-_]*)?)*)/g
    case 'track|album':
      return /^(https?:\/\/)?open.spotify.com\/(?:intl-[a-zA-Z]{2}\/)?(?<entityType>track|album)\/(?<spotifyID>[a-zA-Z0-9\-\_\.]+)(([?&][a-zA-Z]+(=[a-zA-Z0-9-_]*)?)*)/g
    case 'spotify':
      return socialLinkRegex.spotify
    case 'spotifyShort':
      return socialLinkRegex.spotifyShort
    default:
      return socialLinkRegex.spotify
  }
}

export default socialLinkRegex

export type SpotifyRegexGroup<T> = {
  entityType: T
  spotifyID: string
}
