import { getSpotifyRegex } from '~/helpers/mediaLinks/socialLinkRegexes'

export function getQuery(url: string, queryKey: string): string | null {
  if (!url || !queryKey) return null

  const queryString = url.split('?')[1]
  if (!queryString) return null

  const queryArr = queryString.split('&')

  for (let i = queryArr.length - 1; i >= 0; i--) {
    const query = queryArr[i]
    const split = query.split('=')
    const key = split[0]

    if (key === queryKey) return split[1]
  }
  return null
}

export function getLastPath(url: string): string {
  const data = removeQuery(url).split('/')
  return data[data.length - 1]
}

export function removeQuery(url: string): string {
  if (url && url.length) return url.split('?')[0]

  return url
}

export function isUrl(url: string) {
  return (
    url.match(
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.\D]+$/gi,
    ) !== null
  )
}

/**
 * Validates if the url is a youtube url.
 *
 * Here's a helpful link to test many different youtube url formats: https://gist.github.com/rodrigoborgesdeoliveira/987683cfbfcc8d800192da1e73adc486.
 * @param {string} url - The url to test.
 * @returns {boolean} - True if the url is a youtube url, false otherwise.
 */
export function isYoutubeUrl(url: string): boolean {
  const regExp =
    /^(https?:\/\/)?((www|m)\.)?(youtu(be)?)\.(com\/watch\?v=|be\/)\S*([\?&][a-zA-Z]+(=[a-zA-Z0-9-_]*))*$/gim
  const match = url.match(regExp)

  if (match === null) return false

  return !!(getQuery(url, 'v') ?? false) || !!getLastPath(url)
}

export function isSoundCloudUrl(url: string): boolean {
  url = removeQuery(url)
  const appLinkRegex = /^(https?:\/\/)?soundcloud\.app\.goo\.gl\/\S*(\?\S*)?$/gi
  const regexp = /^(https?:\/\/)?(www\.)?(on\.)?soundcloud\.com\/\S*(\?\S*)?$/i
  const excludeSets =
    /((https:\/\/)|(http:\/\/)|(www.)|(m.)|(s))+(soundcloud.com\/)+[a-zA-Z0-9-._]+(\/)+sets/
  const captureId =
    /soundcloud.com\/[a-zA-Z0-9-._]*\/?[a-zA-Z0-9-._]*\/?([a-zA-Z0-9-._]*)/
  const id = captureId.exec(url)

  if (url.match(appLinkRegex)) return true

  if (!url.match(regexp)) return false

  if (url.match(excludeSets) !== null) return false

  if (id && id[1]) return !!id[1]

  return true
}

export function isAudiomackTrackUrl(url: string): boolean {
  url = removeQuery(url)
  const amTrackUrlRegexp1 =
    /^((https:\/\/)|(http:\/\/))?(www.)?audiomack.com\/[a-zA-Z0-9-._]+\/song\/[a-zA-Z0-9-._]+/gi
  const amTrackUrlRegexp2 =
    /^((https:\/\/)|(http:\/\/))?(www.)?audiomack.com\/song\/[a-zA-Z0-9-._]+\/[a-zA-Z0-9-._]+/gi
  return (
    !!url.match(amTrackUrlRegexp1)?.length ||
    !!url.match(amTrackUrlRegexp2)?.length
  )
}

export function isDefaultSpotifyUrl(url: string): boolean {
  const formattedUrl = removeQuery(url)
  const regexp = getSpotifyRegex('spotify')
  return Boolean(formattedUrl.match(regexp))
}

export function isShortenSpotifyUrl(url: string): boolean {
  const formattedUrl = removeQuery(url)
  const regexp = getSpotifyRegex('spotifyShort')
  return Boolean(formattedUrl.match(regexp))
}

export function isSpotifyUrl(url: string): boolean {
  return isDefaultSpotifyUrl(url) || isShortenSpotifyUrl(url)
}

export function isBandcampUrl(url: string): boolean {
  const regexp =
    /^(https?:\/\/)?(?:www.)?([a-z0-9-]*).bandcamp.com\/track\/[a-zA-Z0-9-_]*$/gim
  return url.match(regexp) !== null
}
